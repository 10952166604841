<template>
     <div class="home" id=""><div id="" class=""><h1 class="" id="">Impressum</h1></div>
     <div id="" class=""><p>
    <br/>
    Angaben gemäß § 5 TMG:<span class=""><br/>
    <br/>
    Marco Bräuer<br/>
    <br/>
    Kontakt: &#79;&#114;&#116;&#115;&#98;&#114;&#97;&#110;&#100;&#109;&#101;&#105;&#115;&#116;&#101;&#114;&#64;&#102;&#101;&#117;&#101;&#114;&#119;&#101;&#104;&#114;&#45;&#108;&#117;&#110;&#101;&#115;&#116;&#101;&#100;&#116;&#46;&#100;&#101;<br/>
    <br/>
    Freiwillige Feuerwehr Beverstedt, Ortsfeuerwehr Lunestedt<br/>
    Gewerbekamp 20<br/>
    27616 Beverstedt<br/>
    <br/>
    E-Mail: &#79;&#114;&#116;&#115;&#98;&#114;&#97;&#110;&#100;&#109;&#101;&#105;&#115;&#116;&#101;&#114;&#64;&#102;&#101;&#117;&#101;&#114;&#119;&#101;&#104;&#114;&#45;&#108;&#117;&#110;&#101;&#115;&#116;&#101;&#100;&#116;&#46;&#100;&#101;<br/>
    <br/>
    Aufsichtsbehörde: Gemeinde Beverstedt<br/></span>
</p>

<p>
    <span class=""><br/>
    Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:<br/>
    <br/>
    Marco Bräuer<br/>
    Gewerbekamp 20<br/>
    27616 Beverstedt<br/>
    <br/>
    <br/>
    <br/>
    Haftungsausschluss (Disclaimer)<br/>
    <br/>
    Haftung für Inhalte<br/>
    <br/>
    Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den 
    allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht
    verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen 
    zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.<br/>
    <br/>
    Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen 
    bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt
    der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden 
    Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.<br/>
    <br/>
    Haftung für Links<br/>
    <br/>
    Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen 
    Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. 
    Für die Inhalte
    der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. 
    Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft.
    Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.<br/>
    <br/>
    Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete 
    Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir
    derartige Links umgehend entfernen.<br/>
    <br/>
    Urheberrecht<br/>
    <br/>
    Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen 
    dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung
    außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen 
    Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht
    kommerziellen Gebrauch gestattet.<br/>
    <br/>
    Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die 
    Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie
    trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden 
    Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend
    entfernen.<br/></span></p></div></div>
</template>
<script>
</script>
<style>
</style>